import { mapEnumToOptions } from '@abp/ng.core';

export enum AgentType {
  UserAgent = 1,
  SystemAgent = 2,
  ManagementAgent = 3,
  ExchangeIngestAgent = 5,
  Core = 6,
  CentralMigration = 7,
  AgentWatchdog = 8,
  ModulesWatchdog = 9,
  Bridgehead = 10,
  CredentialManager = 11,
}

export const agentTypeOptions = mapEnumToOptions(AgentType);
